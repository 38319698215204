import React from 'react';

function App() {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-sky-700">
      <h1 className='text-3xl font-mono text-white'>coming soon</h1>
    </div>
  );
}

export default App;
